
import RevealableInput from '@/components/RevealableInput.vue'
import { passwordCustomValidation } from '@/helpers/system'
import { reactive, defineComponent, toRefs } from 'vue'
import useSnackbar from '@/composables/useSnackbar'
import { updateBuyerPassword } from '@/expressway-api/auth.api'
import useVuelidate from '@vuelidate/core'
import Spinner from '@/components/Spinner.vue'
import { helpers, required } from '@vuelidate/validators'
import { sameAs } from 'vuelidate/lib/validators'
import { AxiosError } from 'axios'

export default defineComponent({
  components: {
    RevealableInput,
    Spinner
  },
  setup () {
    const { setSnackbar } = useSnackbar()
    const state = reactive({
      oldPassword: '',
      password: '',
      loading: false,
      confirmPassword: '',
      response: ''
    })
    const rules = {
      password: passwordCustomValidation,
      confirmPassword: {
        required,
        sameAsPassword: helpers.withMessage(
          'Passwords do not match',
          sameAs(() => state.password))
      },
      oldPassword: {
        required
      }
    }
    const validation = useVuelidate(rules, state)
    const updatePassword = async () => {
      state.response = ''
      if (await validation.value.$validate() && !state.loading) {
        state.loading = true
        updateBuyerPassword(state.password, state.oldPassword).then(() => {
          state.loading = false
          state.response = 'Password Updated successfully'
          state.password = ''
          state.confirmPassword = ''
          state.oldPassword = ''
          validation.value.$reset()
        }).catch((e: AxiosError) => {
          setSnackbar(e.message)
          state.loading = false
        })
      }
    }
    return {
      v$: validation,
      updatePassword,
      ...toRefs(state)
    }
  }
})
